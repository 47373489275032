import React from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react'


const EditUser = () => {
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Edit User</strong>
          </CCardHeader>
          <CCardBody>
          
              <CForm>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">First Name</CFormLabel>
                  <CFormInput
                    type="text"
                    id="exampleFormControlInput1"
                  
                  />
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Last Name</CFormLabel>
                  <CFormInput
                    type="text"
                    id="exampleFormControlInput1"
                  
                  />
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Mobile No</CFormLabel>
                  <CFormInput
                    type="text"
                    id="exampleFormControlInput1"
                  
                  />
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Email Id</CFormLabel>
                  <CFormInput
                    type="email"
                    id="exampleFormControlInput1"
               
                  />
                  <CButton color="info" className='mx-1 mt-3'>Save</CButton>
                  <CButton color="danger" className='mx-1 mt-3'>Cancel</CButton>
                </div>
              </CForm>
            
          </CCardBody>
        </CCard>
      </CCol>
      
    </CRow>
  )
}

export default EditUser
