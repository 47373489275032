import React from "react";
import {
  CButton,
  CDropdown,
} from "@coreui/react";
import Swal from "sweetalert2";

const AppHeaderDropdown = () => {
  // const navigate = useNavigate();

  // const [adminName, setAdminName] = useState();

  // function getAdmin() {
  //   const get_admin_name = localStorage.getItem("admin_name");
  //   setAdminName(get_admin_name);
  // }


  // useEffect(() => {
  //   getAdmin();
  // }, []);

  function handleLogout() {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log me out!",
    }).then((result) => {
      if (result.isConfirmed) {
        const isToken = localStorage.getItem("admin");
        if (isToken) {
          localStorage.removeItem("admin");
          localStorage.removeItem("admin_name");
          window.location.reload();
        } else {
          console.log("token error ");
        }
      }
    });
  }

  return (
    <CDropdown variant="nav-item">
      {/* <CDropdownToggle placement="bottom-end" className="py-0" caret={false}> */}
        {/* <CAvatar src={avatar8} size="md" /> */}
        {/* <CButton className="profile-icon-btn">
          <CIcon icon={cilUser} />
        </CButton> */}
      {/* </CDropdownToggle> */}
      <CButton color="primary" onClick={handleLogout}>Logout</CButton>
      {/* <CDropdownMenu className="pt-0" placement="bottom-end">

     
        <CDropdownItem onClick={handleLogout}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Log out
        </CDropdownItem>
      </CDropdownMenu> */}
    </CDropdown>
  );
};

export default AppHeaderDropdown;
