const { useEffect } = require("react");

function useDisableNumberInputScroll(ref) {
  useEffect(() => {
    const handleWheel = (event) => {
      if (event.target === ref.current) {
        event.preventDefault();
      }
    };
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => document.removeEventListener("wheel", handleWheel);
  }, [ref]);
}

export default useDisableNumberInputScroll;
