import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./auth.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../assets/logo/logo.png";
import Swal from "sweetalert2";
import { sendForgotPasswordLink } from "src/Api/Api";
import bgauth from "../assets/banner.5854503b3a99045f7b47.jpg";
import { CircularProgress } from "@mui/material";

export default function forgetPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      // Make API request using the function from api.jsx
      const { success, data, error } = await sendForgotPasswordLink(email);

      if (success) {
        Swal.fire({
          icon: "success",
          title: "Mail Sent Successfully",
          text: "The mail will expire after 1 hour",
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: error,
          allowOutsideClick: false,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          sx={{
            backgroundImage: `url(${bgauth})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          sm={12}
          md={5}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          className="px-1"
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img className="w-50 mt-5" src={logo} alt="logo" />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Send Link"
                )}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/" variant="body2">
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
