import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid} from "@mui/x-data-grid";
import { CButton, CCard, CCardHeader, CRow, CBadge } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { getAllGames } from "src/Api/Api";

const ViewGamesAndCourt = () => {
  const navigate = useNavigate();
  const [gameData, setGameData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const games = await getAllGames();
        setGameData(games);
      } catch (error) {
        console.error("Error fetching game data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameData();
  }, []); 


  
  const columns = [
    { field: "index", headerName: "ID", flex: 1, minWidth: 100},
    {
      field: "game_Name",
      headerName: "Game Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "courts",
      headerName: "No Of Courts",
      renderCell: (params) => params.row.courts.length,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        new Date(params.value).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <CBadge color={params.value ? "success" : "danger"}>{params.value ? "Active" : "Inactive"}</CBadge>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <CButton
          color="primary"
          size="sm"
          onClick={() => navigate(`/game/edit-game-court/${params.row._id}`)}
        >
          Edit
        </CButton>
      ),
    },
  ];

  return (
    <>
    
      <CCard className="mb-4">
        <CCardHeader>
          <strong className="mb-2">List of Games & Courts</strong>
        </CCardHeader>

        <CRow></CRow>
        <Box sx={{ height: "70vh" }}>
          <DataGrid
            rows={gameData.map((row, index) => ({ ...row, index: index + 1 }))}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 8 } },
            }}
            disableRowSelectionOnClick
            getRowId={(row) => row.index}
            loading={loading}
          />
        </Box>
      </CCard>
    </>
  );
};

export default ViewGamesAndCourt;