// ViewCoupons.jsx
import { CBadge, CButton, CCard, CCardHeader } from "@coreui/react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCoupon, getAllCoupons } from "src/Api/Api";
import Swal from "sweetalert2";

const ViewCoupons = () => {
  const navigate = useNavigate();
  const [couponsData, setCouponsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const data = await getAllCoupons();
      if (data) {
        setCouponsData(data);
      } 
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (id) => {
    // Add logic for edit action
    navigate(`/coupons/edit-coupon/${id}`);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        const response = await deleteCoupon({ id });
        // console.log(response,"response from delete coupon")
        if (response) {
          fetchData();
          Swal.fire({
            icon: "success",
            title: `${response?.message}`,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error deleting coupon",
          text: error?.response?.data?.message || "An error occurred",
        });
      }
    }
  };

  const formatStartDate = (dateString) => {
    const startDate = new Date(dateString);
    return startDate.toLocaleDateString();
  };

  const formatEndDate = (dateString) => {
    const endDate = new Date(dateString);
    return endDate.toLocaleDateString();
  };

  const columns = [
    { field: "index", headerName: "ID", flex: 1, minWidth: 100 },
    { field: "couponName", headerName: "Coupon Name", flex: 1, minWidth: 100 },
    { field: "couponType", headerName: "Coupon Type", flex: 1, minWidth: 150 },
    { field: "couponAmount", headerName: "Amount", wlex: 1, minWidth: 100 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => formatStartDate(params.value),
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => formatEndDate(params.value),
    },
    {
      field: "status",
      headerName: "Status",
      filterable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <CBadge color={params.value ? "success" : "danger"}>
          {params.value ? "Active" : "Inactive"}
        </CBadge>
      ),
    },
    {
      field: "valid",
      headerName: "Valid",
      filterable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <CBadge color={params.value ? "success" : "danger"}>
          {params.value ? "Valid" : "Expired"}
        </CBadge>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      width: 200,
      renderCell: (params) => (
        <>
          <CButton
            color="primary"
            size="sm"
            onClick={() => handleEdit(params.row._id)}
          >
            Edit
          </CButton>
          <CButton
            className="mx-2 text-white"
            color="danger"
            size="sm"
            onClick={() => handleDelete(params.row._id)}
          >
            Delete
          </CButton>
        </>
      ),
    },
  ];

  return (
    <CCard className="mb-4">
      <CCardHeader>
        <strong className="mb-2">List of Coupons</strong>
      </CCardHeader>

      <Box sx={{ height: "70vh" }}>
        <DataGrid
          rows={couponsData?.map((row, index) => ({
            ...row,
            index: index + 1,
          }))}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          getRowId={(row) => row.index}
          loading={loading}
        />
      </Box>
    </CCard>
  );
};

export default ViewCoupons;
