import Dashboard from "./pages/dashboard/Dashboard";
import ListUser from "./pages/user/ListUser";
import EditUser from "./pages/user/EditUser";
import AddGameCourt from "./pages/gamesAndCourts/AddGameCourt";
import EditGameAndCourt from "./pages/gamesAndCourts/EditGameAndCourt";
import ViewGamesAndCourt from "./pages/gamesAndCourts/ViewGamesAndCourt";
import AddBooking from "./pages/bookings/AddBooking";
import BookSummary from "./pages/bookings/BookSummary";
import Reports from "./pages/reports/Reports";
import AddCoupons from "./pages/Coupons/AddCoupons";
import ViewCoupons from "./pages/Coupons/ViewCoupons";
import EditCoupon from "./pages/Coupons/EditCoupon";

const routes = [
  // Dashboard
  { path: "/dashboard",  element: Dashboard , },

  // User Routes
  { path: "/user/list-user",  element: ListUser },
  { path: "/user/edit-user", element: EditUser },

  // Game & Court routes
  { path: "/game/add-game-court",  element: AddGameCourt },
  { path: "/game/edit-game-court/:id", element: EditGameAndCourt },
  { path: "/game/view-games-court", element: ViewGamesAndCourt },

  // Booking Routes
  { path: "/booking/add-new-booking",  element: AddBooking },
  { path: "/booking/booking-summary",  element: BookSummary },

  // coupon management
  { path: "/coupons/add-coupon", element: AddCoupons },
  { path: "/coupons/edit-coupon/:id",element: EditCoupon },
  { path: "/coupons/view-coupons", element: ViewCoupons },

  // sales & revenue
  { path: "/reports", element: Reports },

];

export default routes;
