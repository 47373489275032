import {
  CBadge,
  CButton,
  CCard,
  CCardHeader,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import React, { useState, useEffect, useRef } from "react";
import "./booking.css";
import {
  getAllGames,
  getAllGameCourts,
  getTimeSlotsByDate,
  createBooking,
  checkTimeSlotAvailability,
  applyCoupon,
  getAllCoupons,
} from "src/Api/Api";
import Swal from "sweetalert2";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useDisableNumberInputScroll from "src/hooks/useDisableNumberInputScroll";

const AddBooking = () => {
  const navigate = useNavigate();

  const [userName, setuserName] = useState();
  const [email, setEmail] = useState("propadelahmedabad@gmail.com");
  const [phoneno, setphoneno] = useState("8849788752");
  const [isEmailValid, setisEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [games, setGames] = useState([]);

  const [selectedGame, setSelectedGame] = useState(null);
  const [courts, setCourts] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const [bookedTimeSlots, setBookedTimeSlots] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState(30);

  const [discountAmt, setDiscountedAmt] = useState();
  const [coupon, setCoupon] = useState("");
  const [couponDetails, setCouponDetails] = useState();
  const [couponOptions, setCouponOptions] = useState([]);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const gamesData = await getAllGames();
        setGames(gamesData);
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    };

    fetchGames();
  }, []);

  useEffect(() => {
    if (selectedCourt) {
      const startTime = selectedCourt.from;
      const endTime = selectedCourt.to;
      const generatedTimeSlots = generateTimeSlots(startTime, endTime);
      setTimeSlots(generatedTimeSlots);
    }
  }, [selectedCourt]);

  useEffect(() => {
    const fetchBookedTimeSlots = async () => {
      if (selectedDate && selectedCourt) {
        try {
          const timeSlots = await getTimeSlotsByDate(
            selectedCourt._id,
            selectedDate
          );

          setBookedTimeSlots(timeSlots);
        } catch (error) {
          console.error("Error fetching booked time slots:", error);
        }
      }
    };

    fetchBookedTimeSlots();
  }, [selectedDate, selectedCourt, selectedGame]);

  const generateTimeSlots = (from, to) => {
    const timeSlots = [];
    const startTime = moment(from, "hh:mm A");
    const endTime = moment(to, "hh:mm A");

    while (startTime.isBefore(endTime)) {
      const formattedStartTime = startTime.format("hh:mm A");
      // Check if the current time slot falls within the excluded time ranges
      if (
        !(
          (startTime.isSameOrAfter(moment("02:30 AM", "hh:mm A")) &&
            startTime.isBefore(moment("06:00 AM", "hh:mm A"))) ||
          (startTime.isSameOrAfter(moment("09:30 AM", "hh:mm A")) &&
            startTime.isBefore(moment("04:00 PM", "hh:mm A")))
        )
      ) {
        timeSlots.push(formattedStartTime);
      }
      startTime.add(30, "minutes");
    }

    return timeSlots;
  };

  const handleGameChange = async (e) => {
    const gameId = e.target.value;
    const selectedGame = games.find((game) => game._id === gameId);
    setSelectedGame(selectedGame);
    setSelectedCourt(null); // Reset selected court when game changes

    // reseting coupon
    setCouponDetails(null);
    setDiscountedAmt(null);
    setCoupon("");
    // // Fetch courts for the selected game
    try {
      const courtsData = await getAllGameCourts(gameId);
      setCourts(courtsData);
    } catch (error) {
      console.error("Error fetching courts:", error);
    }
  };

  const handleCourtChange = async (e) => {
    const courtId = e.target.value;
    const selectedCourt = courts.find((court) => court._id === courtId);
    setSelectedCourt(selectedCourt);

    // Automatically select the first available date when the court changes
    if (selectedCourt && selectedCourt.days.length > 0) {
      const firstAvailableDate = getSevenDays(selectedCourt.days)
        .filter((day) => day.enabled)
        .map((day) => day.date)[0];
      setSelectedDate(firstAvailableDate);
    } else {
      setSelectedDate(null);
    }

    // reseting coupon
    setCouponDetails(null);
    setDiscountedAmt(null);
  };

  const handleDurationChange = async (e) => {
    setSelectedDuration(parseInt(e.target.value));
    setSelectedTimeSlots([]);
    // reseting coupon
    setCouponDetails(null);
    setDiscountedAmt(null);
    setCoupon("");
  };

  const getSevenDays = (selectedDays) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date().getDay();
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const sevenDays = [];

    for (let i = 0; i < 7; i++) {
      const dayIndex = (today + i) % 7;
      const date = new Date(currentYear, currentMonth - 1, currentDay + i);
      const formattedDate = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;

      const dayName = days[dayIndex];
      const isDaySelected = selectedDays.includes(dayName);

      sevenDays.push({
        day: dayName,
        date: formattedDate,
        enabled: isDaySelected,
      });
    }

    return sevenDays;
  };

  const daysArray = selectedCourt ? getSevenDays(selectedCourt.days) : [];

  const handleTimeSlotClick = async (index) => {
    const selectedStartTime = timeSlots[index].replace(/\s/g, "");
    const durationInMinutes = parseInt(selectedDuration);
    const slotsCount = Math.floor(durationInMinutes / 30);
    const selectedTimeSlotsCopy = [];

    for (let i = 0; i < slotsCount; i++) {
      const startTime = moment(selectedStartTime, "hh:mmA")
        .add(30 * i, "minutes")
        .format("hh:mmA");
      selectedTimeSlotsCopy.push(startTime);
    }

    let time;

    if (selectedDuration === 30) {
      time = `${selectedTimeSlotsCopy[selectedTimeSlotsCopy.length - 1]}`;
    } else {
      time = `${selectedStartTime}-${
        selectedTimeSlotsCopy[selectedTimeSlotsCopy.length - 1]
      }`;
    }

    try {
      const response = await checkTimeSlotAvailability(
        selectedCourt._id,
        selectedDate,
        time
      );

      // If the slot is available (status code 200)
      if (response.status === 200) {
        setSelectedTimeSlots(selectedTimeSlotsCopy);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `${error?.response?.data?.message}`,
        allowOutsideClick: false,
      });
      // Clear previously selected slots
      setSelectedTimeSlots([]);
    }
  };

  const handleDateClick = (date) => {
    // Check if the clicked date is enabled
    const clickedDate = daysArray.find((item) => item.date === date);

    if (clickedDate && clickedDate.enabled) {
      // Set the selected date
      setSelectedDate(date);
    }
    setSelectedTimeSlots([]);
    // reseting coupon
    setCouponDetails(null);
    setDiscountedAmt(null);
    setCoupon("");
  };

  const price = selectedTimeSlots?.length * selectedCourt?.price || 0;

  // phone onchange validation
  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    // Validate the phone number in real-time
    const isValid = /^\d{10}$/.test(phoneNumber);
    setIsPhoneNumberValid(isValid);
    setphoneno(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !userName ||
      !email ||
      !selectedGame ||
      !selectedCourt ||
      !selectedDate ||
      selectedTimeSlots.length === 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Please fill in all the required fields.",
        allowOutsideClick: false,
      });
      return;
    }

    const cleanTimeSlots = selectedTimeSlots.map((timeSlot) =>
      timeSlot.replace(/\s/g, "")
    );

    const bookingPayload = {
      date: selectedDate,
      userName,
      userEmail: email,
      couponId: couponDetails?._id,
      gameId: selectedGame._id,
      courtId: selectedCourt._id,
      phoneNo: parseInt(phoneno),
      price: OrderTotal(),
      onlineBooking: false,
      timeSlot: cleanTimeSlots.map((time) => ({ time })),
    };
    try {
      const response = await createBooking(bookingPayload);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Booking Confirmed",
          allowOutsideClick: false,
        }).then(() => {
          navigate("/booking/booking-summary");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.message ?? "error booking",
        allowOutsideClick: false,
      });
    }
  };

  const isTimeSlotSelected = (timeSlot) => {
    const trimmedTimeSlot = timeSlot.replace(/\s/g, "");
    return selectedTimeSlots[0] === trimmedTimeSlot;
  };

  const isTimeSlotBooked = (timeSlot) => {
    // Convert the time slot to the format used in the data
    const formattedTimeSlot = moment(timeSlot, "hh:mmA")
      .format("hh:mmA")
      .trim();

    return bookedTimeSlots?.some(
      (bookedSlot) =>
        bookedSlot?.time.replace(/\s/g, "") ===
        formattedTimeSlot.replace(/\s/g, "")
    );
  };

  const isPastTimeSlot = (timeSlot) => {
    if (!selectedDate) {
      return false;
    }

    const currentTime = moment();
    const selectedDateTime = moment(
      `${selectedDate} ${timeSlot}`,
      "DD/MM/YYYY hh:mm A"
    );

    // Compare the selected date and time with the current date and time
    return selectedDateTime.isBefore(currentTime);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setisEmailValid(isValid);
    setEmail(email);
  };

  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
    ApplyPromoCode(e.target.value);
  };

  const ApplyPromoCode = async (coupon) => {
    try {
      const response = await applyCoupon(coupon);
      if (response) {
        setCouponDetails(response);
        Swal.fire({
          icon: "success",
          title: "Coupon Applied",
          text: `Promo Code Applied`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      setCouponDetails(null);
      setDiscountedAmt(null);
      setCoupon("");
      Swal.fire({
        icon: "question",
        title: "Coupon Error",
        text: error?.response?.data?.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  useEffect(() => {
    if (couponDetails) {
      discountedAmt();
    }
  }, [couponDetails]);

  async function getCoupons() {
    try {
      const response = await getAllCoupons();
      setCouponOptions(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCoupons();
  }, [selectedTimeSlots, selectedCourt, selectedDate]);

  function discountedAmt() {
    if (
      couponDetails?.couponName === "morning300" &&
      couponDetails?.couponType === "valueDiscount"
    ) {
      const morningDiscount =
        couponDetails?.couponAmount * selectedTimeSlots.length;
      setDiscountedAmt(morningDiscount);
    } else {
      if (couponDetails?.couponType === "valueDiscount") {
        setDiscountedAmt(couponDetails?.couponAmount);
      } else if (couponDetails?.couponType === "percentageDiscount") {
        let discountedPrice = (
          (bookingData?.totalAmount * couponDetails.couponAmount) /
          100
        ).toFixed(2);
        setDiscountedAmt(discountedPrice);
      }
    }
  }

  function OrderTotal() {
    if (discountAmt) {
      const finalAmount = price - discountAmt;
      return finalAmount;
    } else {
      return price;
    }
  }

  const inputTypeNumberRef = useRef(null);
  useDisableNumberInputScroll(inputTypeNumberRef);
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Add New Booking</strong>
          </CCardHeader>
          <CForm className="p-3">
            {/* User Name input */}
            <CRow>
              {/* name */}
              <CCol lg="4">
                <div className="mb-3">
                  <CFormLabel>User Name</CFormLabel>
                  <CFormInput
                    type="text"
                    onChange={(e) => setuserName(e.target.value)}
                    value={userName}
                    required
                  />
                </div>
              </CCol>
              {/* email */}
              <CCol lg="4">
                <div className="mb-3">
                  <CFormLabel>Email</CFormLabel>
                  <CFormInput
                    type="email"
                    onChange={handleEmailChange}
                    value={email}
                    invalid={!isEmailValid}
                  />
                  <CFormFeedback invalid>
                    Please enter a valid email address.
                  </CFormFeedback>
                </div>
              </CCol>
              <CCol lg="4">
                <div className="mb-3">
                  <CFormLabel>Phone no</CFormLabel>
                  <CFormInput
                    type="number"
                    ref={inputTypeNumberRef}
                    onChange={handlePhoneNumberChange}
                    value={phoneno}
                    invalid={!isPhoneNumberValid}
                  />
                  <CFormFeedback invalid>
                    Please enter a valid 10-digit phone number.
                  </CFormFeedback>
                </div>
              </CCol>
            </CRow>

            {/* Phone no  input */}

            {/* Select Game and Select Court inputs */}
            <div className="mb-3">
              <CRow>
                <CCol lg={4}>
                  <CFormLabel>Select Game</CFormLabel>
                  <CFormSelect
                    onChange={handleGameChange}
                    aria-label="Default select example"
                    disabled={games?.length === 0}
                  >
                    <option>Open this select menu</option>
                    {games
                      .filter((game) => game.status)
                      .map((game) => (
                        <option key={game._id} value={game._id}>
                          {game.game_Name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol lg={4}>
                  <CFormLabel>Select Court</CFormLabel>
                  <CFormSelect
                    onChange={handleCourtChange}
                    aria-label="Default select example"
                    disabled={!selectedGame}
                  >
                    <option>Open this select menu</option>
                    {courts
                      .filter((court) => court.status)
                      .map((court) => (
                        <option key={court._id} value={court._id}>
                          {court.court_Name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol lg={4}>
                  <CFormLabel>Select Duration</CFormLabel>
                  <CFormSelect
                    onChange={(e) => handleDurationChange(e)}
                    aria-label="Default select example"
                  >
                    <option value={30}>30 min</option>
                    <option value={60}>60 min</option>
                    <option value={90}>90 min</option>
                    <option value={120}>120 min</option>
                    <option value={180}>180 min</option>
                  </CFormSelect>
                </CCol>
              </CRow>
            </div>

            {/* Select Date input */}
            <div className="mb-3">
              <CFormLabel>Select Date</CFormLabel>
              {selectedCourt ? (
                <div className="slots-div">
                  {daysArray.map((item, index) => (
                    <CBadge
                      className={`p-2 ${
                        item.enabled ? "enabled" : "disabled"
                      } ${selectedDate === item.date ? "selected" : ""}`}
                      key={index}
                      color={item.enabled ? "info" : "light"}
                      onClick={() => handleDateClick(item.date)}
                    >
                      <span>{item.date}-</span>
                      <span>{item.day} </span>
                    </CBadge>
                  ))}
                </div>
              ) : (
                <div className="empty-slots-div">
                  <span className="text-danger">
                    Please select Game & Slot to view available Date
                  </span>
                </div>
              )}
            </div>

            {/* Select Timings input */}
            <div className="mb-3">
              <CFormLabel>Select Timings</CFormLabel>
              <div className="row">
                {selectedCourt ? (
                  timeSlots.slice(0, -1).map((timeSlot, index) => {
                    if (isPastTimeSlot(timeSlot)) {
                      return null;
                    }
                    return (
                      <div
                        className={`col-12 col-sm-4 col-md-3`}
                        key={index}
                        onClick={
                          isTimeSlotBooked(timeSlot)
                            ? null
                            : () => handleTimeSlotClick(index)
                        }
                      >
                        <div
                          className={`time-slot ${
                            isTimeSlotSelected(timeSlot) ? "checked" : ""
                          } ${
                            isTimeSlotBooked(timeSlot) ? "disabled" : "active"
                          }`}
                        >
                          <span>{timeSlot} </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="container">
                    <div className="empty-slots-div">
                      <span className="text-danger">
                        Please select Game & Slot to view available time slots
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <hr />

            <div className="d-flex flex-wrap justify-content-between">
              <div className="mb-3">
                <CFormLabel>Select Coupon:</CFormLabel>
                <CFormSelect
                  value={coupon}
                  onChange={handleCouponChange}
                  aria-label="Default select example"
                  disabled={selectedTimeSlots.length === 0}
                >
                  <option value="">Select Coupon</option>
                  {couponOptions?.map((coupon, index) => (
                    <option key={index} value={coupon.couponName}>
                      {coupon.couponName}
                    </option>
                  ))}
                </CFormSelect>
              </div>
              <div className="order-box">
                <CFormLabel>
                  Price : <strong>{!isNaN(price) ? price : 0}</strong>
                </CFormLabel>
                {couponDetails && (
                  <CFormLabel>
                    Coupon Applied : <strong>-{discountAmt}</strong>
                  </CFormLabel>
                )}
                <CFormLabel>
                  Final Price: <strong>{OrderTotal()}</strong>
                </CFormLabel>
              </div>
            </div>

            {/* Cancel and Book buttons */}
            <div className="d-flex justify-content-end">
              {/* <CButton color="danger text-white">Cancel</CButton> */}
              <CButton
                color="primary"
                onClick={handleSubmit}
                disabled={!selectedCourt}
              >
                Book
              </CButton>
            </div>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AddBooking;
