import React, { useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";
import "./addcourt.css";
import CourtForm from "./CourtForm";
import { createGame } from "src/Api/Api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import GameForm from "./GameForm";
import moment from "moment";

const AddGameCourt = () => {
  const navigate = useNavigate();
  const [gameName, setGameName] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [courts, setCourts] = useState([
    {
      court_no: "",
      name: "",
      from: "",
      to: "",
      days: [],
      price: "",
      slotStatus: false,
      gameID: "",
    },
  ]);

  const handleAddCourt = () => {
    const newCourt = {
      court_no: "",
      name: "",
      from: "",
      to: "",
      days: [],
      price: "",
      slotStatus: false,
    };

    setCourts([...courts, newCourt]);
  };

  const toggleDay = (currentDays, selectedDay) => {
    const updatedDays = [...currentDays];

    if (updatedDays.includes(selectedDay)) {
      // Day is already selected, remove it
      const index = updatedDays.indexOf(selectedDay);
      updatedDays.splice(index, 1);
    } else {
      // Day is not selected, add it
      updatedDays.push(selectedDay);
    }

    return updatedDays;
  };

  const handleCourtChange = (courtIndex, field, value) => {
    const updatedCourts = [...courts];
    updatedCourts[courtIndex][field] =
      field === "court_no" ? Number(value) : value;
    if (field === "from" || field === "to") {
      updatedCourts[courtIndex][field] = moment(value, "h:mm A").format(
        "HH:mm"
      );
    }
    setCourts(updatedCourts);
  };

  const handleRemoveCourt = (indexToRemove) => {
    if (courts.length > 1) {
      const updatedCourts = courts.filter(
        (_, index) => index !== indexToRemove
      );
      setCourts(updatedCourts);
    }
  };

  const handleSubmit = async () => {
    try {
  
      if (!gameName.trim()) {
        Swal.fire({
          icon: "warning",
          title: "Empty Fields",
          text: "Please fill in the game name.",
        });
        return;
      }
  
      // Check if any court name or other required fields are empty
      const emptyCourts = courts.filter(
        (court) =>
          !court.name.trim() ||
          !court.from.trim() ||
          !court.to.trim() ||
          !court.price.trim()
      );
  
      if (emptyCourts.length > 0) {
        Swal.fire({
          icon: "warning",
          title: "Empty Fields",
          text: "Please fill in all required fields for each court.",
        });
        return;
      }
  
      setLoading(true);
      // Assuming that you have all required data for game and courts
      const gameData = { game_Name: gameName };
      const courtData = courts.map((court) => ({
        courtNo: court.court_no,
        court_Name: court.name,
        from: court.from,
        to: court.to,
        price: court.price,
        days: court.days,
      }));
      await createGame(gameData, courtData, image);
  
      Swal.fire({
        icon: "success",
        title: "Submission Successful",
        text: "Your game and court data has been submitted successfully!",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/game/view-games-court");
        }
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "An error occurred while submitting the form.",
      });
    }
  };
  

  return (
    <CCard className="mb-4">
      <CCardHeader>
        <strong>Game Management</strong>
        <h6>Add New Game</h6>
      </CCardHeader>
      <CCardBody>
        {/* game start */}
        <GameForm
          gameName={gameName}
          setGameName={setGameName}
          setImage={setImage}
        />
        {/* game */}

        {/* start court */}
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Court Management</strong>
              </CCardHeader>
              <CCardBody>
                {courts.map((court, index) => (
                  <CourtForm
                    court_no={court.court_no}
                    key={court.id}
                    court={court}
                    courts={courts}
                    index={index}
                    handleCourtChange={handleCourtChange}
                    toggleDay={toggleDay}
                    handleRemoveCourt={handleRemoveCourt}
                  />
                ))}

                {/* add more courts starts */}
                <div className="add-more-court">
                  <CButton
                    color="info"
                    className="mx-1 mt-3"
                    onClick={handleAddCourt}
                  >
                    Add Court
                  </CButton>
                </div>
                {/* add more courts ends */}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <div className="d-flex justify-content-end">
          <CButton color="primary" className="m-3" onClick={handleSubmit}>
            {loading ? <CSpinner size="sm" color="light" /> : "Submit"}
          </CButton>
        </div>
        {/* end court */}
      </CCardBody>
    </CCard>
  );
};

export default AddGameCourt;
