import axios from "axios";

const authApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL || "https://api.propadel.co.in/admin",
});

const api = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL || "https://api.propadel.co.in/admin",
});

export const getToken = async () => {
  const mytoken = await JSON.parse(localStorage.getItem("admin"));

  if (!mytoken || !mytoken.token) {
    window.location.href = "/auth/login";
    return null;
  }

  const admin_token = mytoken.token;
  return admin_token;
};

api.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    // config.headers['Host'] = 'localhost:3000';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (
        status === 500 &&
        data.message &&
        data.message.includes("Token Expired")
      ) {
        localStorage.removeItem("admin");
        window.location.href = "/auth/login";
      }
    }

    return Promise.reject(error);
  }
);

// before login pages api

export const sendForgotPasswordLink = async (email) => {
  try {
    const response = await authApi.post("/forgetPassword", {
      email,
    });

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error: "Error Sending Mail" };
  }
};

export const login = async (email, password) => {
  try {
    const response = await authApi.post(`/login`, {
      email,
      password,
    });
    const { admin, token } = response.data;
    // Store token in local storage
    // await localStorage.setItem("admin", JSON.stringify({ token }));
    localStorage.setItem("admin_name", admin.name);

    if (response.data.success || response.data.token) {
      // const admin = response.data;
      localStorage.setItem("admin", JSON.stringify({ token }));
      return { success: true, data: response.data };
    }
  } catch (error) {
    return {
      success: false,
      error: "Invalid email or password. Please try again.",
    };
  }
};

// resetPassword
export const resetPassowrdApi = async (token, password, confirmPassword) => {
  try {
    const response = await authApi.put(`/resetPassowrd?token=${token}`, {
      password,
      confirmPassword,
    });
    return response;
  } catch (error) {

    return error.response;
  }
};

// after login pages api

// get all users with Courts
export const getAllUsers = async () => {
  try {
    const response = await api.get("/allUsers");
    const usersWithIds = response.data.map((user, index) => ({
      ...user,
      id: index + 1, // Use index + 1 as a simple way to create unique IDs
    }));
    return usersWithIds;
  } catch (error) {
    throw error;
  }
};

// add game
export const createGame = async (gameData, courtData, image) => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("gameData", JSON.stringify(gameData));
  formData.append("courtData", JSON.stringify(courtData));

  try {
    const response = await api.post("/createGame", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// get all Games with Courts
export const getAllGames = async () => {
  try {
    const response = await api.get("/getAllGame");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// get game by id
export const getGameById = async (gameId) => {
  try {
    const response = await api.get(`/getGame/${gameId}`);
    return response.data;
  } catch (error) {
    throw error; 
  }
};

// update game
export const editGame = async (formData) => {
  try {
    const response = await api.put(`/editGame`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error to handle it in the component if needed
  }
};

// offline booking
// Function to get all courts for a specific game
// export const getAllGameCourts = async (gameId) => {
//   try {
//     const response = await fetch(`/getAllGameCourt/${gameId}`);
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error("Error fetching courts:", error);
//     throw error;
//   }
// };

export const getAllGameCourts = async (gameId) => {
  try {
    const response = await api.get(`/getAllGameCourt/${gameId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// get all Bookings
export const getAllBookings = async () => {
  try {
    const response = await api.get("/getBooking");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// update court
export const editCourt = async (courtData) => {
  try {
    const response = await api.put(`/editCourt`, courtData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Check Booked Time Slot
// export const getTimeSlotsByDate = async (courtId , date) => {
//   try {
//     const response = await fetch(`/getCourtTimeSlot?courtId=${courtId}&date=${date}`);
//     const data = await response.json();
//     return data[0]?.timeslots || [];
//   } catch (error) {
//     console.error("Error fetching time slots:", error);
//     return [];
//   }
// };

export const getTimeSlotsByDate = async (courtId, date) => {
  try {
    const response = await api.get(
      `/getCourtTimeSlot?courtId=${courtId}&date=${date}`
    );
    const data = response.data;
    return data?.timeslots || [];
  } catch (error) {
    return [];
  }
};

// create booking
export const createBooking = async (bookingPayload) => {
  try {
    const response = await api.post(`/createBooking`, bookingPayload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// cancel booking
export const cancelBooking = async (bookingId) => {
  try {
    const response = await api.put(`/cancelBooking`, { id: bookingId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// check duration wise
export const checkTimeSlotAvailability = async (
  courtId,
  date,
  time,

) => {
  try {
    const response = await api.get(
      `/checkDuration?courtId=${courtId}&date=${date}&time=${time}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// coupon post
export const createCoupon = async (formData) => {
  try {
    const response = await api.post(`/createCoupon`, formData);
    return response;
  } catch (error) {
    throw error;
  }
};

// get all coupons
export const getAllCoupons = async () => {
  try {
    const response = await api.get(`/getAllCoupon`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// get single coupon
export const getCoupon = async (id) => {
  try {
    const response = await api.get(`/getCoupon/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// update coupon
export const updateCoupon = async (couponData) => {
  try {
    const response = await api.put(`/editCoupon`, couponData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// delete coupon
export const deleteCoupon = async (id) => {
  try {
    const response = await api.delete(`/deleteCoupon`, { data: id });
    return response.data;
  } catch (error) {
    throw error;
  }
};


// handling coupon
export const applyCoupon = async (promoCode) => {
  try {
    const response = await api.get(`/applyCoupon?couponName=${promoCode}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};



