import React, { useRef } from "react";
import {
  CBadge,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CRow,
} from "@coreui/react";
import useDisableNumberInputScroll from "src/hooks/useDisableNumberInputScroll";

const CourtForm = ({
  court_no,
  court,
  index,
  handleCourtChange,
  toggleDay,
  handleRemoveCourt,
  courts,
}) => {

  const numberInputRef = useRef(null);
  const numberInputRefCourtNo = useRef(null);
  useDisableNumberInputScroll(numberInputRef);
  useDisableNumberInputScroll(numberInputRefCourtNo);

  return (
    <CForm key={court.id}>
      <CBadge color="primary" className="p-2 mb-3">
        Add Court {index + 1}
      </CBadge>
      <div className="mb-3">
        <CFormLabel htmlFor={`courtNo${court_no}`}>Add Court No</CFormLabel>
        <CFormInput
          type="number"
          id={`courtNo${court_no}`}
          value={court_no}
          ref={numberInputRefCourtNo}
          onChange={(e) => handleCourtChange(index, "court_no", e.target.value)}
        />
      </div>

      <div className="mb-3">
        <CFormLabel htmlFor={`courtName${court.id}`}>Add Court Name</CFormLabel>
        <CFormInput
          type="text"
          id={`courtName${court.id}`}
          value={court.name}
          onChange={(e) => handleCourtChange(index, "name", e.target.value)}
        />
      </div>

      <CRow>
        <strong className="mb-3">Court Timings</strong>
        <CCol lg={6}>
          <CFormLabel htmlFor={`courtFrom${court.id}`}>From</CFormLabel>
          <CFormInput
            type="time"
            id={`courtFrom${court.id}`}
            value={court.from}
            onChange={(e) => handleCourtChange(index, "from", e.target.value)}
          />
        </CCol>
        <CCol lg={6}>
          <CFormLabel htmlFor={`courtTo${court.id}`}>To</CFormLabel>
          <CFormInput
            type="time"
            id={`courtTo${court.id}`}
            value={court.to}
            onChange={(e) => handleCourtChange(index, "to", e.target.value)}
          />
        </CCol>
      </CRow>

      <div className="mt-3">
        <strong>Select Days</strong>
        <div className="days-selection mt-3">
          {[
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ].map((day) => (
            <CBadge
              key={day}
              color={court.days.includes(day) ? "success" : "danger"}
              onClick={() =>
                handleCourtChange(index, "days", toggleDay(court.days, day))
              }
            >
              {day}
            </CBadge>
          ))}
        </div>
      </div>

      <CRow>
        <strong className="mt-3">Price</strong>
        <CCol lg={6}>
          <CFormLabel htmlFor={`courtPrice${court.id}`}>
            Price Per Slot
          </CFormLabel>
          <CFormInput
            type="number"
            ref={numberInputRef}
            id={`courtPrice${court.id}`}
            value={court.price}
            onChange={(e) => handleCourtChange(index, "price", e.target.value)}
          />
        </CCol>
        <CCol lg={6}>
          <CFormLabel htmlFor={`courtSlotStatus${court.id}`}>
            Slot Status
          </CFormLabel>
          <CFormSwitch
            label="Activate"
            id={`courtSlotStatus${court.id}`}
            checked={court.slotStatus}
            onChange={(e) =>
              handleCourtChange(index, "slotStatus", e.target.checked)
            }
          />
        </CCol>
      </CRow>
      {courts.length > 1 && (
        <CButton
          color="danger"
          className="mx-1 mt-3"
          onClick={() => handleRemoveCourt(index)}
        >
          Remove Court
        </CButton>
      )}
      <hr style={{ border: "2px solid #000" }} />
    </CForm>
  );
};

export default CourtForm;
