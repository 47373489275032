import logo from "../assets/logo/logo.png";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./auth.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import bgauth from "../assets/banner.5854503b3a99045f7b47.jpg";
import { login } from "src/Api/Api";

export default function Login() {
  const [loginError, setLoginError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const email = event.currentTarget.email.value;
      const password = event.currentTarget.password.value;

      const { success, data, error } = await login(email, password);

      if (success) {
        window.location.href = "/dashboard";
      } else {
        setLoginError(error);
      }
    } catch (error) {
      setLoginError("Something went wrong. Please try again.");
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={false}
        md={7}
        sx={{
          backgroundImage: `url(${bgauth})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        sm={12}
        md={5}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        className="px-3"
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img className="w-50 mt-5" src={logo} alt="logo" />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              variant="standard"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              variant="standard"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {loginError && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {loginError}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/auth/forgotPassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
