import { Suspense } from "react";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import "./scss/style.scss";
import "sweetalert2/dist/sweetalert2.css";
import Login from "./Auth/Login";
import ResetPassword from "./Auth/ResetPassword";
import ForgotPassword from "./Auth/ForgotPassword";
import DefaultLayout from "./layout/DefaultLayout";
import { useIsAuthenticated } from "./hooks/useAuth";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);


const App = () => {
  const isAuthenticated = useIsAuthenticated();
 
  
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            {
              !isAuthenticated ?
              <>
              <Route path="/auth/login" element={<Login/>} />
              <Route path="*" element={<Navigate to="/auth/login" replace/>} />
              <Route  path="/auth/forgotPassword" element={<ForgotPassword />} />
              <Route path="/auth/resetPassword" element={<ResetPassword />} /> 
              </> :
              <>
               <Route path="*" element={ <DefaultLayout />} />
              </>
            }         
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
