// GameForm.js
import React from "react";
import {
  CButton,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
} from "@coreui/react";
import "./game.css"

const GameForm = ({
  gameName,
  setGameName,
  setImage,
  handleFileChange,
  isActive,
  setIsActive,
  previewImage,
  isHovered,
  setIsHovered,
  isEditForm,
  handleUpdateGame,
}) => {
  return (
    <CForm>
      {/* Game Image Input */}
      <div className="mb-3">
        <CFormLabel htmlFor="exampleFormControlInput1">Game Image</CFormLabel>
        {isEditForm && previewImage && (
          <div className="mb-3 image-container">
            <label
              htmlFor="gameImage"
              className="image-label"
              // onMouseEnter={() => setIsHovered(true)}
              // onMouseLeave={() => setIsHovered(false)}
            />
            <img
              src={previewImage}
              alt="Game Preview"
              className="image-preview"
              onChange={handleFileChange}
            />
            {/* {isHovered && (
              <div className="overlay">
                <p className="overlay-text">Click to Select Image</p>
              </div>
            )} */}
          </div>
        )}
        <CFormInput
          type="file"
          onChange={
            isEditForm ? handleFileChange : (e) => setImage(e.target.files[0])
          }
        />
      </div>

      {/* Game Name Input */}
      <div className="mb-3">
        <CFormLabel htmlFor="exampleFormControlInput1">Game Name</CFormLabel>
        <CFormInput
          type="text"
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
        />
      </div>

      {/* Active Status Input (only for edit form) */}
      {isEditForm && (
        <div className="mb-3">
           <CFormLabel htmlFor="exampleFormControlInput1">Game Status</CFormLabel>
          <CFormSwitch
            // label="Active"
            id="formSwitchCheckChecked"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
        </div>
      )}

      {isEditForm && (
        <div className="d-flex justify-content-end">
          <CButton color="primary" className="m-3" onClick={handleUpdateGame}>
            Update Game
          </CButton>
        </div>
      )}
    </CForm>
  );
};

export default GameForm;
