import logo from "../assets/logo/logo.png";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./auth.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { resetPassowrdApi } from "src/Api/Api";
import Swal from "sweetalert2";
import { Grid } from "@mui/material";
import bgauth from "../assets/banner.5854503b3a99045f7b47.jpg";

export default function ResetPassword() {
  const [email, setEmailHash] = useState();
  const [token, setToken] = useState();
  const toggle = () => setmodal(!modal);

  const query = new URLSearchParams(useLocation().search);
 
  useEffect(() => {
    let token = query.get("token");
    setToken(token);
    setEmailHash(query.get("emailHash"));
  }, []);

  const resetPassword = async (e) => {
    e.preventDefault();
    const password = e.target.elements.password.value;
    const confirmPassword = e.target.elements.confirmPassword.value;
    if (password !== confirmPassword) {
      return;
    }

    try {
      const response = await resetPassowrdApi(token, password, confirmPassword);
      Swal.fire({
        icon: "success",
        title: "Password Changed Successfully ",
        allowOutsideClick: false,
      }).then(() => {
        window.location.href = "/"
      })
      // Redirect or perform any other action based on the response
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: "Error updating Password",
            text: "The mail will expire after 1 hour",
            allowOutsideClick: false,
          })
      // Handle error if needed
    }
  };
  return (
    <>
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={false}
        md={7}
        sx={{
          backgroundImage: `url(${bgauth})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        sm={12}
        md={5}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        className="px-3"
        sx={{
          width: "100%",
        }}
      >
         <Box
        sx={{
          width: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <img className="w-50 mt-5" src={logo} alt="logo" />
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box
          component="form"
          onSubmit={resetPassword}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            type="password"
            label="New Password"
            name="password"
            autoComplete="new-password"
            variant="standard"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            autoComplete="new-password"
            variant="standard"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
      </Grid>
    </Grid>
  
    </>
   
  );
}
