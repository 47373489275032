import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  CBadge,
  CButton,
  CCard,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { getAllUsers } from "src/Api/Api";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CIcon from "@coreui/icons-react";
import { cilLoopCircular } from "@coreui/icons";
import moment from "moment";

const ListUser = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // Add state for filter values
  const [filteredUsers, setFilteredUsers] = useState([]);
  const hasData = filteredUsers?.length > 0;
   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");
   const [statusFilter, setStatusFilter] = useState("All");

   const fetchData = async () => {
    try {
      const data = await getAllUsers();
      setUsers(data);
      setFilteredUsers(data)
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const applyFilter = () => {
    const filteredData = users.filter((user) => {
      const createdAt = moment(user.createdAt); // Parse createdAt as a moment object
  
      const isStatusMatch =
        statusFilter === "All" || (user.status ? "Active" : "Inactive") === statusFilter;
  
      // Check if startDate is provided and createdAt is greater than or equal to startDate
      const isStartDateMatch = !startDate || createdAt.isSameOrAfter(startDate, 'day');
  
      // Check if endDate is provided and createdAt is less than or equal to endDate
      const isEndDateMatch = !endDate || createdAt.isSameOrBefore(endDate, 'day');
  
      return isStartDateMatch && isEndDateMatch && isStatusMatch;
    });
  
    setFilteredUsers(filteredData);
  };
  


  const columns = [
    { field: "id", headerName: "ID", width: 90, flex: 1, minWidth: 50 },
    {
      field: "Username",
      headerName: "User name",
      width: 200,
      valueGetter: (params) => `${params.row.fullName}`,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "phoneNumber",
      headerName: "Phone no",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "email",
      headerName: "Email ID",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "createdAt",
      headerName: "Registered On",
      type: "number",
      filterable: false,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <CBadge color={params.row.status ? "success" : "danger"}>
          {params.row.status ? "Active" : "Inactive"}
        </CBadge>
      ),
    },
  ];

  const handleExportPDF = () => {
    const visibleColumns = columns.filter((column) => column.hide !== true);

    const pdfColumns = visibleColumns.map((column) => ({
      title: column.headerName,
      dataKey: column.field,
    }));
  
    const pdfRows = filteredUsers.map((user) => {
      const row = {};
      visibleColumns.forEach((column) => {
        row[column.field] = user[column.field];
      });
      return row;
    });
  
    const doc = new jsPDF();
    doc.autoTable({ columns: pdfColumns, body: pdfRows });
    doc.save("users.pdf");
  };

  const handleExportCSV = () => {
    const csvData = filteredUsers.map((user) => ({
      id: user.id,
      Username: user.fullName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      createdAt: new Date(user.createdAt).toLocaleDateString(),
      Status: user.status ? "Active" : "Inactive", 
    }));

    const csvHeaders = [
      { label: "ID", key: "id" },
      { label: "User Name", key: "Username" }, 
      { label: "Phone Number", key: "phoneNumber" },
      { label: "Email ID", key: "email" },
      { label: "Registered On", key: "createdAt" },
      { label: "Status", key: "Status" }, 
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvHeaders.map((header) => header.label).join(",") +
      "\n" +
      csvData
        .map((row) =>
          csvHeaders.map((header) => `"${row[header.key]}"`).join(",")
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function CustomToolbar() {
    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <CDropdown>
            <CDropdownToggle color="info" disabled={!hasData}>
              Export
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem onClick={handleExportCSV}>CSV</CDropdownItem>
              <CDropdownItem onClick={handleExportPDF}>PDF</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          
        </GridToolbarContainer>
        <hr />
      </>
    );
  }
  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setStatusFilter("All");
    fetchData()
  };

  const sortedData = filteredUsers
  .slice() // Create a copy to avoid mutating the original data
  .sort((a, b) => {
    // Assuming "Date" is in ISO format and a string
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA; // Sort in descending order
  })
  .map((row, index) => ({
    ...row,
    id: index + 1, // Add an index starting from 1
  }));

  return (
    <>
    {/* filter */}
    <CCard>
        <CCardHeader>
          <CRow>
            <CCol md="3">
              <div className="all-center">
                <label htmlFor="">From</label>
                <CFormInput
                  id="startDate-input"
                  type="date"
                  className="me-2"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </CCol>

            <CCol md="3">
              <div className="all-center">
                <label htmlFor="">To</label>
                <CFormInput
                  id="endDate-input"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </CCol>

            <CCol md="3">
              <CFormSelect
                custom
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="All">All</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </CFormSelect>
            </CCol>

            <CCol md="3">
              <div className="d-flex align-items-center justify-content-end">
                <CIcon
                  icon={cilLoopCircular}
                  className="ms-2"
                  size="xl"
                  style={{ cursor: "pointer" }}
                  onClick={resetFilters}
                />

                <CButton
                  className="ms-3"
                  onClick={applyFilter}
                >
                  Apply filter
                </CButton>
                </div>
            </CCol>
            

            
          </CRow>
        </CCardHeader>
      </CCard>
{/* filter end */}
      <CCard className="mb-4 d-flex">
        <CCardHeader className="d-flex align-items-center justify-content-between">
          <strong className="mb-2">Users List</strong>
         
        </CCardHeader>
        <Box sx={{ height: "70vh" }}>
          <DataGrid
             rows={sortedData}
            columns={columns}
            loading={loading}
            initialState={{
              pagination: { paginationModel: { pageSize: 8 } },
            }}
            disableRowSelectionOnClick
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Box>
      </CCard>
    </>
  );
};

export default ListUser;
