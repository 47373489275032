// EditCoupon.jsx
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CRow,
} from "@coreui/react";

import Swal from "sweetalert2";
import { getCoupon, updateCoupon } from "src/Api/Api";
import useDisableNumberInputScroll from "src/hooks/useDisableNumberInputScroll";

const EditCoupon = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  
  const [couponData, setCouponData] = useState({
    couponName: "",
    couponType: "",
    startDate: "",
    endDate: "",
    couponAmount: "",
    status: true,
  });

const fetchCouponData = async () => {
    try {
      const response = await getCoupon(id);
      const formattedCouponData = {
        ...response.coupon,
        startDate: response.coupon.startDate.slice(0, 16),
        endDate: response.coupon.endDate.slice(0, 16),
      };
      setCouponData(formattedCouponData);
    } catch (error) {
      console.error("Error fetching coupon data:", error);
    }
  };

  useEffect(() => {
    fetchCouponData();
  }, [id]);

  const handleInputChange = (field, value) => {
    setCouponData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleUpdate = async () => {
    const updatedCouponData = { ...couponData, id: couponData._id };
    try {
     const response =  await updateCoupon(updatedCouponData) 
     if (response) {
        Swal.fire({
            icon: "success",
            title: `${response?.message}`,
            allowOutsideClick: false
          }).then(() => {
            navigate(`/coupons/view-coupons`)
          });
     }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error updating coupon",
        text: error?.response?.data?.message || "An error occurred",
      });
    }
  };

  const inputTypeNumberRef = useRef(null)
  useDisableNumberInputScroll(inputTypeNumberRef)

  return (
    <CCard>
      <CCardHeader>
        <strong>Edit Coupon</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          {/* Coupon Name */}
          <CCol md="6">
            <CFormLabel>
              <strong> Coupon Name : </strong>
            </CFormLabel>
            <CFormInput
              type="text"
              placeholder="Enter Coupon Name"
              value={couponData.couponName}
              onChange={(e) => handleInputChange("couponName", e.target.value)}
            />
          </CCol>

          {/* Coupon Type */}
          <CCol md="6">
            <CFormLabel>
              <strong> Select Coupon Type : </strong>
            </CFormLabel>
            <CFormSelect
              aria-label="Default select example"
              value={couponData.couponType}
              onChange={(e) => handleInputChange("couponType", e.target.value)}
            >
              <option value="percentageDiscount">Percentage Discount</option>
              <option value="valueDiscount">Value Discount</option>
            </CFormSelect>
          </CCol>

          {/* Start Date */}
          <CCol md="6" className="mt-4">
            <CFormLabel>
              <strong> Start Date : </strong>
            </CFormLabel>
            <CFormInput
              type="datetime-local"
              value={couponData.startDate}
              onChange={(e) => handleInputChange("startDate", e.target.value)}
            />
          </CCol>

          {/* End Date */}
          <CCol md="6" className="mt-4">
            <CFormLabel>
              <strong> End Date : </strong>
            </CFormLabel>
            <CFormInput
              type="datetime-local"
              value={couponData.endDate}
              onChange={(e) => handleInputChange("endDate", e.target.value)}
            />
          </CCol>

          {/* Amount */}
          <CCol md="6" className="mt-4">
            <CFormLabel>
              <strong> Amount : </strong>
            </CFormLabel>
            <CFormInput
              type="number"
              ref={inputTypeNumberRef}
              placeholder="Enter Amount"
              value={couponData.couponAmount}
              onChange={(e) =>
                handleInputChange("couponAmount", e.target.value)
              }
            />
          </CCol>

          {/* Status */}
          <CCol md="6" className="mt-4">
            <CFormLabel>
              <strong> Status : </strong>
            </CFormLabel>
            <CFormSwitch
              id="statusSwitch"
              checked={couponData.status}
              onChange={(e) => handleInputChange("status", e.target.checked)}
            />
          </CCol>
        </CRow>

        {/* Submit Button */}
        <CButton onClick={handleUpdate} className="mt-4">
          Update
        </CButton>
      </CCardBody>
    </CCard>
  );
};

export default EditCoupon;
