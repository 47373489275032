import React, { useEffect, useState } from "react";
import { CCard, CCol, CRow, CWidgetStatsA } from "@coreui/react";
import { CChart, CChartBar, CChartLine } from "@coreui/react-chartjs";
import { getAllBookings, getAllGames, getAllUsers } from "src/Api/Api";
import { getStyle } from "@coreui/utils";

const Dashboard = () => {
  const [users, setusers] = useState();
  const [games, setGames] = useState();

  const [totalBookings, setTotalBookings] = useState();

  async function fetchAllUsers() {
    try {
      const response = await getAllUsers();
      // console.log(response?.length)
      setusers(response?.length);
    } catch (error) {
      console.log("error fetching users", error);
    }
  }

  async function fetchGamesAndCourts() {
    try {
      const response = await getAllGames();
      setGames(response?.length);
    } catch (error) {
      console.log("error fetching games", error);
    }
  }

  async function fetchBookings() {
    try {
      const response = await getAllBookings();
      setTotalBookings(response?.length);
    } catch (error) {
      console.log("error fetching booking", error);
    }
  }

  useEffect(() => {
    fetchBookings();
    fetchGamesAndCourts();
    fetchAllUsers();
  }, []);

  return (
    <>
      <CRow>
        <CCol sm={6} lg={4}>
          <CWidgetStatsA
            className="mb-4 pb-4"
            color="primary"
            value={<>{users}</>}
            title="Users"
          />
        </CCol>
        <CCol sm={6} lg={4}>
          <CWidgetStatsA
            className="mb-4 pb-4"
            color="info"
            value={<>{games}</>}
            title="Games"
          />
        </CCol>

        <CCol sm={6} lg={4}>
          <CWidgetStatsA
            className="mb-4 pb-4"
            color="danger"
            value={<>{totalBookings}</>}
            title="Bookings"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCard>
          <CChart
            type="bar"
            data={{
              labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
              ],
              datasets: [
                {
                  label: "Booking Report",
                  backgroundColor: "#f87979",
                  data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
                },
              ],
            }}
            labels="months"
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: getStyle("--cui-body-color"),
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    color: getStyle("--cui-border-color-translucent"),
                  },
                  ticks: {
                    color: getStyle("--cui-body-color"),
                  },
                },
                y: {
                  grid: {
                    color: getStyle("--cui-border-color-translucent"),
                  },
                  ticks: {
                    color: getStyle("--cui-body-color"),
                  },
                },
              },
            }}
          />
        </CCard>
      </CRow>
    </>
  );
};

export default Dashboard;
