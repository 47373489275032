import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilTennis,
  cilGroup,
  cilTennisBall,
  cilAddressBook,
  cilSpeedometer,
  cilClipboard,
  cilBarChart,
  cilMoney
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  // user management starts
  {
    component: CNavTitle,
    name: "User Management",
  },
  {
    component: CNavItem,
    name: "View Users",
    to: "/user/list-user",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  // users management - end
  // Games Management - start
  {
    component: CNavTitle,
    name: "Games & Court Management",
  },
  {
    component: CNavItem,
    name: "Add Game and Court",
    to: "/game/add-game-court",
    icon: <CIcon icon={cilTennis} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "View Games and Court",
    to: "/game/view-games-court",
    icon: <CIcon icon={cilTennisBall} customClassName="nav-icon" />,
  },
  //  Games Management - end
 
  // Bookings & Payment  - start
  {
    component: CNavTitle,
    name: "Bookings & Payment Summary",
  },
  {
    component: CNavItem,
    name: "Add New Booking",
    to: "/booking/add-new-booking",
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "View Bookings",
    to: "/booking/booking-summary",
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
  },
  //   Bookings & Payment  - end

   // coupon - start
   {
    component: CNavTitle,
    name: "Coupon Management",
  },
  {
    component: CNavItem,
    name: "Add Coupons",
    to: "/coupons/add-coupon",
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "View Coupons",
    to: "/coupons/view-coupons",
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },

  // reports - start
  {
    component: CNavTitle,
    name: "Reports",
  },
  {
    component: CNavItem,
    name: "Revenue Reports",
    to: "/reports",
    icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
  },

];

export default _nav;
