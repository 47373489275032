import React, { useState, useEffect, useRef } from "react";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CRow,
} from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  editCourt,
  editGame,
  getAllGameCourts,
  getGameById,
} from "src/Api/Api";
import "./game.css";
import Swal from "sweetalert2";
import GameForm from "./GameForm";
import moment from "moment";
import useDisableNumberInputScroll from "src/hooks/useDisableNumberInputScroll";

const EditGameAndCourt = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const [gameName, setGameName] = useState("");
  const [gameImage, setGameImage] = useState("");
  const [game_image_name, setgame_image_name] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  // const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(null);

  // Edit Game Opeartion - start
  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const response = await getGameById(id);
        setIsActive(response.status);
        setgame_image_name(response.game_Image_Name);
        setGameName(response.game_Name);
        setGameImage(response.game_Image_Url);
        setPreviewImage(response.game_Image_Url);
      } catch (error) {
        console.error("Error fetching game data:", error);
      }
    };

    fetchGameData();
  }, [id]);



  const handleUpdateGame = async () => {
    try {
      // Ensure gameImage and gameName are provided
      if (!gameImage || !gameName) {
        alert("Game Image and Game Name are required");
        return;
      }

      const formData = new FormData();
      formData.append("image", gameImage);
      formData.append("id", id);
      formData.append("game_Name", gameName);
      formData.append("status", isActive);

      // Call the editGame function to make the PUT request
      await editGame(formData);
    
      Swal.fire({
        icon: "success",
        title: "Game Updated",
        text: "Your game data has been updated successfully!",
      });

      // Optionally, you can display a success message or redirect the user
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error Updating Game",
      });
    }
  };

  const handleFileChange = (e) => {
    const newImage = e.target.files[0];
    const modifiedFile = new File([newImage], game_image_name, {
      type: newImage.type,
    });
    setGameImage(modifiedFile);

    const reader = new FileReader();

    reader.onload = () => {
      setPreviewImage(reader.result);
    };

    if (newImage) {
      reader.readAsDataURL(newImage);
    }
  };

  //  Edit Game Opeartion - end

  // fetch & update Courts - start

  const [courts, setCourts] = useState();

  useEffect(() => {
    const getAllCourts = async () => {
      try {
        const courtsData = await getAllGameCourts(id);
        const mappedCourts = courtsData.map((court) => {
          return {
            ...court,
            id: court._id, // Rename _id to id
          };
        });
        setCourts(mappedCourts);
      } catch (error) {
        console.error("Error fetching courts:", error);
      }
    };

    getAllCourts();
  }, [id]);

  // fetch & update Courts - end
  const handleChange = (courtIndex, field, value) => {
    const updatedCourts = [...courts];
    updatedCourts[courtIndex][field] = field === 'court_no' ? Number(value) : value;
    if (field === "from" || field === "to") {
      updatedCourts[courtIndex][field] = moment(value, "h:mm A").format("HH:mm");
    }
    setCourts(updatedCourts);
  };

  const toggleDay = (daysArray, selectedDay) => {
    if (daysArray.includes(selectedDay)) {
      // If the day is already in the array, remove it
      return daysArray.filter((day) => day !== selectedDay);
    } else {
      // If the day is not in the array, add it
      return [...daysArray, selectedDay];
    }
  };

  const handleAddCourt = () => {
    const newCourt = {
      gameId : id ,
      courtNo: "",
      court_Name: "",
      from: "",
      to: "",
      days: [],
      price: 0,
      status: false,
    };

    setCourts([...courts, newCourt]);
  };

  const handleRemoveCourt = (indexToRemove) => {
    if (
      courts.length > 1 &&
      indexToRemove >= 0 &&
      indexToRemove < courts.length
    ) {
      // Check if the court is not fetched (you may need to adjust the condition based on your data)
      if (!courts[indexToRemove]._id) {
        const updatedCourts = courts.filter(
          (_, index) => index !== indexToRemove
        );
        setCourts(updatedCourts);
      } else {
        // Handle case where trying to remove a fetched court
        alert("Fetched courts cannot be removed.");
      }
    }
  };

  const handleUpdate = async () => {
    try {
      await editCourt(courts);
      Swal.fire({
        icon: "success",
        title: "Court Updated",
        text: "Your Court data has been updated successfully!",
        allowOutsideClick: false
      }).then(() => {
        navigate("/game/view-games-court")
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "error updating court",
      });

    }
  };

  const numberInputRef = useRef(null);
  const numberInputRefCourtNo = useRef(null)
  useDisableNumberInputScroll(numberInputRef);
  useDisableNumberInputScroll(numberInputRefCourtNo);
  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Game Management</strong>
          <h6>Edit Game</h6>
        </CCardHeader>
        <CCardBody>
          <GameForm
            gameName={gameName}
            setGameName={setGameName}
            setImage={setGameImage}
            handleFileChange={handleFileChange}
            isActive={isActive}
            setIsActive={setIsActive}
            previewImage={previewImage}
            isEditForm={true}
            // isHovered={isHovered}
            // setIsHovered={setIsHovered}
            handleUpdateGame={handleUpdateGame}
          />
        </CCardBody>
      </CCard>
      {/* court management start */}
      <CCard className="mb-2">
        <CCardHeader>
          <strong>Court Management</strong>
          <h6>Edit Court</h6>
        </CCardHeader>
        <CCardBody>
          {courts &&
            courts.map((court, index) => (
              <CForm key={court.id}>
                <h6 className="text-primary">Court {index + 1}</h6>
                <div className="mb-3">
                  <CFormLabel htmlFor={`courtNo${court.courtNo}`}>
                    Add Court No
                  </CFormLabel>
                  <CFormInput
                    type="number"
                    ref={numberInputRefCourtNo}
                    id={`courtNo${court.courtNo}`}
                    value={court.courtNo}
                    onChange={(e) =>
                      handleChange(index, "courtNo", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <CFormLabel htmlFor={`courtName${court.id}`}>
                    Add Court Name
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    id={`courtName${court.id}`}
                    value={court.court_Name}
                    onChange={(e) =>
                      handleChange(index, "court_Name", e.target.value)
                    }
                  />
                </div>

                <CRow>
                  <strong className="mb-3">Court Timings</strong>
                  <CCol lg={6}>
                    <CFormLabel htmlFor={`courtFrom${court.id}`}>
                      From
                    </CFormLabel>
                    <CFormInput
                      type="time"
                      id={`courtFrom${court.id}`}
                      value={court.from}
                      onChange={(e) =>
                        handleChange(index, "from", e.target.value)
                      }
                    />
                  </CCol>
                  <CCol lg={6}>
                    <CFormLabel htmlFor={`courtTo${court.id}`}>To</CFormLabel>
                    <CFormInput
                      type="time"
                      id={`courtTo${court.id}`}
                      value={court.to}
                      onChange={(e) =>
                        handleChange(index, "to", e.target.value)
                      }
                    />
                  </CCol>
                </CRow>

                <div className="mt-3">
                  <strong>Select Days</strong>
                  <div className="days-selection mt-3">
                    {[
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ].map((day) => (
                      <CBadge
                        key={day}
                        className="mx-2"
                        color={court.days.includes(day) ? "success" : "danger"}
                        onClick={() =>
                          handleChange(
                            index,
                            "days",
                            toggleDay(court.days, day)
                          )
                        }
                      >
                        {day}
                      </CBadge>
                    ))}
                  </div>
                </div>

                <CRow>
                  <strong className="mt-3">Price</strong>
                  <CCol lg={6}>
                    <CFormLabel htmlFor={`courtPrice${court.id}`}>
                      Price Per Slot
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      ref={numberInputRef}
                      id={`courtPrice${court.id}`}
                      value={court.price}
                      onChange={(e) =>
                        handleChange(index, "price", e.target.value)
                      }
                    />
                  </CCol>
                  <CCol lg={6}>
                    <CFormLabel htmlFor={`courtSlotStatus${court.id}`}>
                      Court Status
                    </CFormLabel>
                    <CFormSwitch
              
                      id={`courtSlotStatus${court.id}`}
                      checked={court.status}
                      onChange={(e) =>
                        handleChange(index, "status", e.target.checked)
                      }
                    />
                  </CCol>
                </CRow>
                {!court._id && (
                  <CButton
                    color="danger"
                    className="mx-1 mt-3 mb-3"
                    onClick={() => handleRemoveCourt(index)}
                  >
                    Remove Court
                  </CButton>
                )}
                <hr />
              </CForm>
            ))}
          <div className="add-court-section">
            <CButton color="dark" onClick={handleAddCourt}>
              Add More Court
            </CButton>
          </div>
        </CCardBody>
      </CCard>
      <div className="mb-3 update-btn" onClick={handleUpdate}>
        <CButton>Update</CButton>
      </div>
      {/* court management end  */}
    </>
  );
};

export default EditGameAndCourt;
