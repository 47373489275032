import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  CButton,
  CCard,
  CCardHeader,
  CRow,
  CBadge,
  CFormSelect,
  CFormInput,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CCol,
} from "@coreui/react";
import { cancelBooking, getAllBookings } from "../../Api/Api";
import Swal from "sweetalert2";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CIcon from "@coreui/icons-react";
import { cilLoopCircular } from "@coreui/icons";

const BookSummary = () => {
  const [bookingData, setBookingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [bookingStatus, setbookingStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState("all");

  const handleApplyFilter = () => {
    const filteredData = bookingData.filter((booking) => {
      const bookingDate = moment(booking.Date, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      const bookingType = booking.onlineBooking ? "Online" : "Offline";
      const isCancelled = booking.cancel;
      const paymentStatus = booking.PaymentStatus.toLowerCase(); 

      return (
        (!startDate || bookingDate >= startDate) &&
        (!endDate || bookingDate <= endDate) &&
        (bookingStatus === "all" || bookingType === bookingStatus) &&
        (filterStatus !== "cancelled" || isCancelled) &&
        (filterStatus !== "notCancelled" || !isCancelled) &&
        (paymentStatusFilter === "all" ||
          paymentStatus === paymentStatusFilter.toLowerCase())
      );
    });

    setFilteredData(filteredData);
  };

  const handleDateChange = (event) => {
    const { id, value } = event.target;
    if (id === "startDate-input") {
      setStartDate(value);
    } else if (id === "endDate-input") {
      setEndDate(value);
    }
  };

  const fetchBookingData = async () => {
    try {
      const bookings = await getAllBookings();
      const formattedData = bookings.map((game, index) => ({
        id: index + 1,
        _id: game._id,
        UserName: game.userName,
        Phoneno: game.phoneNo,
        Date: moment(game.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        GameName: game.gameDetails.gameName,
        CourtNo: game.courtDetails.courtNo,
        Amount: game.price,
        timeDuration: game.timeDuration,
        transactionId: game.transactionId ?? "-",
        PaymentStatus: game.paymentStatus ?? "-",
        orderId: game.orderId,
        onlineBooking: game.onlineBooking,
        cancel: game.cancel,
      }));
      const reversedData = formattedData.reverse()
      setBookingData(reversedData);
      setFilteredData(reversedData);
    } catch (error) {
      console.error("Error fetching booking data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookingData();
  }, []);

  

  const handleCancelBooking = async (bookingId) => {
    // Display SweetAlert confirmation message
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, cancel it!",
    });

    // If the user confirms, proceed with the cancellation
    if (result.isConfirmed) {
      try {
        await cancelBooking(bookingId);
        Swal.fire({
          icon: "success",
          title: "Booking cancelled",
          text: "Your booking has been cancelled successfully.",
        });

        // Fetch and update the booking data
        fetchBookingData();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error Canceling Booking",
          text: "There was an error canceling your booking.",
        });
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50, flex: 1, minWidth: 50 },
    {
      field: "UserName",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "Phoneno",
      headerName: "Phone No.",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "GameName",
      headerName: "Game Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "CourtNo",
      headerName: "Court No",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "timeDuration",
      headerName: "Slot Timings",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "Date",
      headerName: "date",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "Amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "PaymentStatus",
      headerName: "Payment Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const paymentStatus = params.value;
        let bgcolor = "";
        let textColor = "";
        if (paymentStatus === "pending") {
          bgcolor = "orange";
          textColor = "white";
        } else if (paymentStatus === "success") {
          bgcolor = "green";
          textColor = "white";
        } else if (paymentStatus === "Session Expired") {
          bgcolor = "red";
          textColor = "white";
        }
        return (
          <span
            style={{ background: bgcolor, color: textColor , textTransform: "capitalize" }}
            className="p-1 rounded"
          >
            {paymentStatus}
          </span>
        );
      },
    },
    {
      field: "transactionId",
      headerName: "transaction ID",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "BookingType",
      headerName: "Booking Type",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) =>
        params.row.onlineBooking ? "Online" : "Offline",
      filterable: true,
      filterOptions: ["Online", "Offline"],
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const isCanceled = params.row.cancel;
        return (
          <div>
            {isCanceled ? (
              <span className="text-danger">Cancelled</span>
            ) : (
              <CButton
                color="danger"
                className="text-white"
                size="sm"
                onClick={() => handleCancelBooking(params?.row?._id)}
              >
                Cancel
              </CButton>
            )}
          </div>
        );
      },
    },
  ];

  const handlePDFExport = () => {
    const columns = [
      { title: "ID", key: "id" },
      { title: "Name", key: "UserName" },
      { title: "Game", key: "Game" },
      { title: "Court No", key: "CourtNo" },
      { title: "Phone No", key: "Phoneno" },
      { title: "Slot Timings", key: "timeDuration" },
      { title: "Date", key: "Date" },
      { title: "Amount", key: "Amount" },
      { title: "orderID", key: "orderId" },
      { title: "Transaction ID", key: "transactionId" },
      { title: "Payment Status", key: "PaymentStatus" },
      { title: "Booking Type", key: "BookingType" },
    ];

    const rows = filteredData.map((row) => ({
      id: row.id,
      UserName: row.UserName,
      Phoneno: row.Phoneno,
      Game: row.GameName,
      CourtNo: row.CourtNo,
      timeDuration: row.timeDuration,
      Date: row.Date,
      Amount: row.Amount,
      orderId: row.orderId,
      transactionId: row.transactionId ?? "-",
      PaymentStatus: row.PaymentStatus ?? "-",
      BookingType: row.onlineBooking ? "Online" : "Offline",
    }));

    const doc = new jsPDF();
    doc.text("Booking Summary", 20, 10);

    const tableConfig = {
      startY: 20,
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: [255, 255, 255],
        fontSize: 8,
      },
      bodyStyles: { fontSize: 7 },
      columnStyles: {
        id: { columnWidth: 10 },
        UserName: { columnWidth: 25 },
        Game: { columnWidth: 20 },
        CourtNo: { columnWidth: 15 },
        Phoneno: { columnWidth: 25 },
        timeDuration: { columnWidth: 25 },
        Date: { columnWidth: 25 },
        Amount: { columnWidth: 20 },
        orderId: { columnWidth: 20 },
        transactionId: { columnWidth: 30 },
        PaymentStatus: { columnWidth: 25 },
        BookingType: { columnWidth: 20 },
      },
    };

    doc.autoTable({
      head: [columns.map((col) => col.title)],
      body: rows.map((row) => columns.map((col) => row[col.key])),
      ...tableConfig,
    });

    doc.save("booking_summary.pdf");
  };

  const handleCSVExport = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      "ID,User Name,Game, Court No ,Phone No,Slot Timings,Date,Amount,OrderId,Transaction ID , Payment Status, Booking Type\n" +
      filteredData
        .map((row) =>
          [
            row.id,
            row.UserName,
            row.GameName,
            row.CourtNo,
            row.Phoneno,
            row.timeDuration,
            row.Date,
            row.Amount,
            row.orderId,
            row.transactionId ?? "-",
            row.PaymentStatus ?? "-",
            row.onlineBooking ? "Online" : "Offline",
          ].join(",")
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "booking_summary.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleResetFilters = () => {
    setFilterStatus("all");
    setStartDate("");
    setEndDate("");
    fetchBookingData();
  };

  const hasData = filteredData?.length > 0;
  function CustomToolbar() {
    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton className="justify-content-between" />
        </GridToolbarContainer>
        <hr />
      </>
    );
  }

  const sortedData = filteredData
    .map((row, index) => ({
      ...row,
      id: index + 1,
    }));

  return (
    <>
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol md="2">
              <div className="all-center">
                <label htmlFor="">From</label>
                <CFormInput
                  id="startDate-input"
                  type="date"
                  className="me-2 "
                  value={startDate}
                  onChange={handleDateChange}
                />
              </div>
            </CCol>

            <CCol md="2">
              <div className="all-center">
                <label htmlFor="">To</label>
                <CFormInput
                  id="endDate-input"
                  type="date"
                  value={endDate}
                  onChange={handleDateChange}
                />
              </div>
            </CCol>

            <CCol md="2">
              <CFormSelect
                custom
                value={bookingStatus}
                onChange={(e) => setbookingStatus(e.target.value)}
              >
                <option value="all">All Bookings</option>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
              </CFormSelect>
            </CCol>

            <CCol md="3">
              <CFormSelect
                custom
                value={paymentStatusFilter}
                onChange={(e) => setPaymentStatusFilter(e.target.value)}
              >
                <option value="all">All Payment Status</option>
                <option value="Pending">Pending</option>
                <option value="Success">Success</option>
                <option value="Session Expired">Session Expired</option>
              </CFormSelect>
            </CCol>

            <CCol md="3">
              <div className="d-flex align-items-center">
                <CFormSelect
                  custom
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <option value="all">All Actions</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="notCancelled">Not Cancelled</option>
                </CFormSelect>

                <CIcon
                  icon={cilLoopCircular}
                  className="ms-2"
                  size="xl"
                  onClick={handleResetFilters}
                  style={{ cursor: "pointer" }}
                />

                <CButton className="ms-3" onClick={handleApplyFilter}>
                  Apply
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardHeader>
      </CCard>

      <CCard className="mb-4">
        <CCardHeader>
          <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
            <div>
              <strong className="mb-2">Booking Summary</strong>
            </div>
            <div className="filter-opt">
              <CDropdown>
                <CDropdownToggle color="info" disabled={!hasData}>
                  Export
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem onClick={handleCSVExport}>CSV</CDropdownItem>
                  <CDropdownItem onClick={handlePDFExport}>PDF</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </div>
        </CCardHeader>

        <CRow></CRow>
        <Box sx={{ height: "70vh", overflowX: "auto" }}>
          <DataGrid
            rows={sortedData}
            columns={columns}
            loading={loading}
            initialState={{
              pagination: { paginationModel: { pageSize: 8 } },
            }}
            disableRowSelectionOnClick
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Box>
      </CCard>
    </>
  );
};

export default BookSummary;
