import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CRow,
} from "@coreui/react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCoupon } from "src/Api/Api";
import useDisableNumberInputScroll from "src/hooks/useDisableNumberInputScroll";
import Swal from "sweetalert2";

const AddCoupons = () => {
  const navigate = useNavigate()
  // State to store form data
  const [formData, setFormData] = useState({
    couponName: "",
    couponType: "percentageDiscount",
    couponAmount: "",
    startDate: "",
    endDate: "",
    status: false,
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // If the input is a checkbox, update the state using the 'checked' property
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const isFormValid = () => {
    // Check if any field is empty
    return Object.values(formData).every((value) => {
      if (typeof value === "boolean") {
        return true; 
      }
      return value !== "";
    });
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      const apiResponse = await createCoupon(formData);

      if (apiResponse) {
        // Show success alert using Swal
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Coupon created successfully!',
          allowOutsideClick: false,
        }).then(() => {
          navigate("/coupons/view-coupons")
        });

        // Reset the form after successful submission
        setFormData({
          couponName: "",
          couponType: "percentageDiscount",
          couponAmount: "",
          startDate: "",
          endDate: "",
          status: false,
        });
      }
    } catch (error) {
      // Show error alert using Swal
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.response?.data?.message || 'Something went wrong!',
      });
    }
  };

  const inputTypeNumberRef = useRef(null)
  useDisableNumberInputScroll(inputTypeNumberRef)

  return (
    <CCard>
      <CCardHeader>
        <strong>Coupon Management</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          {/* Coupon Name */}
          <CCol md="6">
            <CFormLabel>
              <strong> Coupon Name : </strong>
            </CFormLabel>
            <CFormInput
              type="text"
              placeholder="Enter Coupon Name"
              name="couponName"
              value={formData.couponName}
              onChange={handleChange}
            />
          </CCol>

          {/* Coupon Type */}
          <CCol md="6">
            <CFormLabel>
              <strong> Select Coupon Type : </strong>
            </CFormLabel>
            <CFormSelect
              aria-label="Default select example"
              name="couponType"
              value={formData.couponType}
              onChange={handleChange}
            >
              <option value="percentageDiscount">Percentage Discount</option>
              <option value="valueDiscount">Value Discount</option>
            </CFormSelect>
          </CCol>

          {/* Start Date */}
          <CCol md="6" className="mt-4">
            <CFormLabel>
              <strong> Start Date : </strong>
            </CFormLabel>
            <CFormInput
              type="datetime-local"
              placeholder="Enter Start Date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </CCol>

          {/* End Date */}
          <CCol md="6" className="mt-4">
            <CFormLabel>
              <strong> End Date : </strong>
            </CFormLabel>
            <CFormInput
              type="datetime-local"
              placeholder="Enter End Date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </CCol>

          {/* Amount */}
          <CCol md="6" className="mt-4">
            <CFormLabel>
              <strong> Amount : </strong>
            </CFormLabel>
            <CFormInput
              type="number"
              ref={inputTypeNumberRef}
              placeholder="Enter Amount"
              name="couponAmount"
              value={formData.couponAmount}
              onChange={handleChange}
            />
          </CCol>

          <CCol md="6" className="mt-4" >
          <CFormLabel>
              <strong> Status : </strong>
            </CFormLabel>
            <CFormSwitch
              id="statusSwitch"
              name="status"
              checked={formData.status}
              onChange={handleChange}
            />
          </CCol>
        </CRow>

        {/* Submit Button */}
        <CButton
          onClick={handleSubmit}
          className="mt-4"
          disabled={!isFormValid()}
        >
          Submit
        </CButton>
      </CCardBody>
    </CCard>
  );
};

export default AddCoupons;
